import {useMutation} from '@apollo/client';
import {
    Komodita,
    Mutation,
    ZadostElektrinaSopHladinaNapeti,
    ZadostElektrinaSopTypNapeti,
    ZadostElektrinaSopZmenaNaOdbernemMiste,
    ZadostSopWebFormResolveInput,
    ZadostSopWebMutationResolveFormInfoArgs,
    ZadostSopWebTypZadosti,
} from '@eon.cz/apollo13-graphql-web';
import {NextPage, NextPageContext} from 'next';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useCommonAction} from '../client/common/Actions/CommonAction';
import {PageRoute} from '../client/common/PageRouteModel';
import {LoadingDialog} from '../client/common/components/dialog/LoadingDialog';
import {PageLayout} from '../client/common/components/layout/PageLayout';
import {CommonMutations} from '../client/common/graphql/CommonMutations';
import {changeFavicon} from '../client/common/utils/CommonUtils';
import {useRozcestnikAction} from '../client/components/rozcestnik/action/RozcestnikAction';
import {Rozcestnik} from '../client/components/rozcestnik/components/Rozcestnik';
import {StoreType} from '../client/lib/StoreType';

type Props = {
    readonly komodita: Komodita;
    readonly typZadosti: ZadostSopWebTypZadosti;
    readonly typNapeti: ZadostElektrinaSopTypNapeti;
    readonly hladinaNapeti: ZadostElektrinaSopHladinaNapeti;
    readonly typZmenyNaOdbernemMiste: ZadostElektrinaSopZmenaNaOdbernemMiste;
    readonly isElektrinaQueryParams: boolean;
    readonly isPlynQueryParams: boolean;
    readonly parseId: string | null;
    readonly selfcare: boolean;
    readonly statusCode: number;
};

const Index: NextPage<Props> = ({
    isPlynQueryParams,
    isElektrinaQueryParams,
    typZadosti,
    typNapeti,
    hladinaNapeti,
    komodita,
    typZmenyNaOdbernemMiste,
    parseId,
    selfcare,
    statusCode,
}) => {
    const {setRozcestnikData} = useRozcestnikAction();
    const {setFormStepPath, setParseId, setEmail, resetStore, setSelfcare} = useCommonAction();
    const [pending, setPending] = useState(false);
    const {push} = useRouter();

    const storedParseId = useSelector((state: StoreType) => state.common.parseId);

    // muattions
    const [sendRezcestnik, {loading}] = useMutation<Mutation, ZadostSopWebMutationResolveFormInfoArgs>(CommonMutations.gql.resolveFormInfo, {
        onCompleted: (res) => {
            const {expectedFormStepPath} = res?.sopWeb?.resolveFormInfo;
            setFormStepPath(expectedFormStepPath);
            push({pathname: PageRoute.VSTUP}, undefined, {shallow: true});
        },
        onError: () => {
            setPending(false);
        },
    });

    const onSubmit = (input: ZadostSopWebFormResolveInput) => {
        setRozcestnikData(input);
        setPending(true);
        sendRezcestnik({variables: {input}});
    };

    useEffect(() => {
        if (parseId && selfcare) {
            setParseId(parseId);
            setSelfcare(selfcare);
        }
    }, [selfcare, parseId, setParseId, setSelfcare]);

    useEffect(() => {
        if (isElektrinaQueryParams) {
            setParseId(undefined);
            setEmail(undefined);
            resetStore();
            setRozcestnikData({typNapeti, hladinaNapeti, typZadosti, komodita, typZmenyNaOdbernemMiste});
            onSubmit({typNapeti: typZmenyNaOdbernemMiste ? undefined : typNapeti, hladinaNapeti, typZadosti, komodita, typZmenyNaOdbernemMiste});
        }
        if (isPlynQueryParams && !storedParseId) {
            changeFavicon(Komodita.PLYN);
            setParseId(undefined);
            setEmail(undefined);
            resetStore();
            sendRezcestnik({variables: {input: {komodita: Komodita.PLYN, typZadosti: ZadostSopWebTypZadosti.NOVY_ODBER}}});
            setRozcestnikData({komodita: Komodita.PLYN, typZadosti: ZadostSopWebTypZadosti.NOVY_ODBER});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isElektrinaQueryParams, komodita, typNapeti, typZadosti, hladinaNapeti]);

    if (loading || pending) {
        return <LoadingDialog open />;
    }

    if (isElektrinaQueryParams || (isPlynQueryParams && !storedParseId)) {
        return null;
    }

    return (
        <PageLayout statusCode={statusCode}>
            <Rozcestnik queryKomodita={komodita} />
        </PageLayout>
    );
};

export const getServerSideProps = async (ctx: NextPageContext) => {
    const {
        query: {komodita, typZadosti, typNapeti, hladinaNapeti, typZmenyNaOdbernemMiste, parseId, selfcare},
    } = ctx;
    const komoditaToUpper = (komodita as string)?.toUpperCase();
    const isElektrinaQueryParams = (komoditaToUpper === Komodita.ELEKTRINA && !!typZadosti && !!typNapeti && !!hladinaNapeti) || !!typZmenyNaOdbernemMiste;
    const isPlynQueryParams = komoditaToUpper === Komodita.PLYN;

    return {
        props: {
            selfcare: selfcare ?? false,
            parseId: parseId ?? null,
            komodita: komoditaToUpper ?? null,
            typZadosti: typZadosti ?? null,
            typNapeti: typNapeti ?? null,
            hladinaNapeti: hladinaNapeti ?? null,
            typZmenyNaOdbernemMiste: typZmenyNaOdbernemMiste ?? null,
            isElektrinaQueryParams,
            isPlynQueryParams,
        },
    };
};

export default Index;
