import {MessageDescriptor} from 'react-intl';

// Funkce pro nastavení vlastností jednotlivývh kroků v rozcestníku
export const getSteps = (formatMessage: (descriptor: MessageDescriptor, values?: Record<string, any>) => string) => {
    return [
        {
            title: formatMessage({id: 'rozcestnik.step.two.title'}),
            description: formatMessage({id: 'rozcestnik.step.two.description'}),
            zmena: true,
            novy: true,
            elektrina: true,
            plyn: true,
        },
        {
            title: formatMessage({id: 'rozcestnik.step.three.title'}),
            description: formatMessage({id: 'rozcestnik.step.three.description'}),
            zmena: false,
            novy: true,
            elektrina: true,
            plyn: false,
        },
        {
            title: formatMessage({id: 'rozcestnik.step.four.title'}),
            description: formatMessage({id: 'rozcestnik.step.four.description'}),
            zmena: false,
            novy: true,
            elektrina: true,
            plyn: false,
        },
        {
            title: formatMessage({id: 'rozcestnik.step.five.title'}),
            description: formatMessage({id: 'rozcestnik.step.five.description'}),
            zmena: true,
            novy: false,
            elektrina: true,
            plyn: false,
        },
    ];
};
