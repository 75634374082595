import {
    Komodita,
    Maybe,
    Parcela,
    PovoleneTypyPriloh,
    ZadostElektrinaSopHladinaNapeti,
    ZadostElektrinaSopTypNapeti,
    ZadostElektrinaSopZmenaNaOdbernemMiste,
    ZadostSopWebBlokInfo,
    ZadostSopWebBlokRozcestnikInput,
    ZadostSopWebBlokSpecifikaceUzemiBusiness,
    ZadostSopWebBlokType,
    ZadostSopWebFormPodDetailAdresa,
    ZadostSopWebFormType,
    ZadostSopWebTypZadosti,
    ZadostSopZakaznikAdresa,
} from '@eon.cz/apollo13-graphql-web';
import {useMediaQuery} from '@mui/material';
import {isEqual, isFunction, omitBy} from 'lodash';
import {NextApiResponse} from 'next';
import {BaseRouter} from 'next/dist/shared/lib/router/router';
import Router from 'next/router';
import {ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import sanitizeHtml from 'sanitize-html';
import {NotificationType} from '../components/notifications/model/NotificationModel';
import {NotificationAddRequest} from '../components/notifications/reducer/NotificationsReducer';
import {formMapPath, stat} from '../constants';
import {FormKind} from '../store/CommonStore';

export const nullAsEmpty = (s: string | null | undefined | Maybe<string>): string => (typeof s === 'string' ? s : '');
export const isNullOrUndefined = <T extends Record<string, unknown> | string | null | ReactNode | unknown>(val: T) => val == null;

export const isNullOrUndefinedOrEmpty = <T extends Record<string, unknown> | string | null | ReactNode | unknown>(val: T) =>
    isNullOrUndefined(val) || val === '';

export const isNotNullOrUndefinedOrEmpty = <T,>(val: T) => !isNullOrUndefinedOrEmpty(val);
/**
 * Parser čísla ve stringu na number ve float typu
 *
 * @param {(number | string)} value
 * @return {number ? undefined}
 */
export const parseDecimal = <T extends number | string | undefined | null>(value: T) => {
    if (typeof value === 'number') {
        return value;
    }
    if (typeof value === 'undefined' || value === null) {
        return 0;
    }
    return Number(parseFloat(value as string).toFixed(3));
};

/**
 * Funkce pro handlová procházení ve formuláři při stisku tlačítka zpět
 *
 * @param {ZadostSopWebBlokType[]} seznam
 * @param {number} step
 * @param {('left' | 'right')} direction
 * @return {*}  {string}
 */
export const cestaZMesta = (seznam: Maybe<ZadostSopWebBlokType>[] | undefined, step: number, direction: 'left' | 'right'): string => {
    const newStep = direction === 'left' ? seznam?.[step - 1] : seznam?.[step + 1];
    return formMapPath[newStep ?? ZadostSopWebBlokType.ROZCESTNIK];
};
/**
 * Funce která validuje,jestli se má nebo nemá provolat query
 *
 * @param {ZadostSopWebBlokType[]} seznam
 * @param {number} step
 * @param {ZadostSopWebBlokType} lastFinishedStep
 * @return {*} boolean
 */
export const isSkipQuery = (
    seznam: ZadostSopWebBlokType[] | Maybe<ZadostSopWebBlokType>[] | undefined,
    step: number | undefined,
    lastFinishedStep: ZadostSopWebBlokType | undefined,
): boolean => {
    const lastBlock = seznam?.findIndex((s) => s === lastFinishedStep) ?? 0;
    return (step ?? 0) > lastBlock;
};

/**
 * Funkce pro vstup do formuláře po návratu na stránku,pokud uživatel vybere,že chce pokračovat v práci v již započaté žádosti
 * @param {ZadostSopWebBlokType[] | undefined | Maybe<ZadostSopWebBlokType>[]} seznam -
 * ZadostSopWebBlokType[] | undefined | Maybe<ZadostSopWebBlokType>[]
 * @param {ZadostSopWebBlokInfo | undefined} lastFinishedBlok - ZadostSopWebBlokInfo | undefined
 * @returns the path to the next step in the form.
 */
export const cestaDoMesta = (seznam: Maybe<ZadostSopWebBlokType>[] | undefined, lastFinishedBlok: ZadostSopWebBlokInfo | undefined): string => {
    const isLastFinishBlokGeoportal = lastFinishedBlok?.typ === ZadostSopWebBlokType.GEOPORTAL;
    const step = isLastFinishBlokGeoportal ? seznam?.[4] : lastFinishedBlok?.typ;
    return formMapPath[step || ZadostSopWebBlokType.ROZCESTNIK] ?? '/osobniUdaje';
};

/**
 * Zkonvertuje povolené typy příloh na mapu podle typu objektu
 *
 * @param {PovoleneTypyPriloh[]} typy
 * @return {*}  {{[key: string]: PovoleneTypyPriloh}}
 */
export const convertPovoleneTypyPriloh = (typy: PovoleneTypyPriloh[]): {[key: string]: PovoleneTypyPriloh} =>
    typy.reduce(
        (map, typ: PovoleneTypyPriloh) => {
            map[typ.typObjektu] = typ;
            return map;
        },
        {} as {[key: string]: PovoleneTypyPriloh},
    );

/**
 * Funkce, která přesune uživatele na správnou stránku
 *
 * @param {ZadostSopWebBlokType[]} formStepPath
 * @param {string} formName
 * @param {(notification: NotificationAddRequest) => {
 *         type: string;
 *         payload: NotificationAddRequest;
 *     }} addNotification
 * @param {(url: string, as?: string, options?: any) => Promise<boolean>} push
 */
export const nextPage = async (
    formStepPath: ZadostSopWebBlokType[] | Maybe<ZadostSopWebBlokType>[] | undefined,
    formName: string,
    addNotification: (notification: NotificationAddRequest) => {
        type: string;
        payload: NotificationAddRequest;
    },
    push: (url: string, as?: string, options?: any) => Promise<boolean>,
): Promise<any> => {
    const step = formStepPath?.findIndex((p) => p === formName) ?? 0;
    const pathname = cestaZMesta(formStepPath, step, 'right');
    try {
        return await push(pathname, undefined, {shallow: true});
    } catch {
        return addNotification({type: NotificationType.ERROR, text: <FormattedMessage id="error.http.notFoundTitle" />});
    }
};
/**
 * Funkce pro formátování adresy
 * @param {(ZadostSopZakaznikAdresa | ZadostSopWebFormPodDetailAdresa | undefined)} adresa
 * @return {*}  {string}
 */
export const formatAddress = (adresa: ZadostSopZakaznikAdresa | Maybe<ZadostSopWebFormPodDetailAdresa> | undefined): string => {
    if (adresa) {
        const {ulice, cisloPopisne, cisloOrientacni, cisloEvidencni, mistniCast, psc} = adresa;
        const obec = 'obec' in adresa ? adresa.obec : undefined;
        const isStat = 'stat' in adresa && adresa.stat;
        const hasCP = cisloPopisne && cisloPopisne.length > 0;
        const hasCO = cisloOrientacni && cisloOrientacni.length > 0;
        const hasCE = cisloEvidencni && cisloEvidencni.length > 0;

        let res = '';

        if (nullAsEmpty(obec)) {
            res += `${obec}`;
        }

        if (nullAsEmpty(mistniCast)) {
            res += `, ${mistniCast}`;
        }

        if (nullAsEmpty(ulice)) {
            res += `, ${ulice}`;
        }

        if (hasCP && hasCO) {
            res += `, č.p.${cisloPopisne}/č.o.${cisloOrientacni}`;
        } else if (hasCO) {
            res += `, č.o.${cisloOrientacni}`;
        } else if (hasCP) {
            res += `, č.p.${cisloPopisne}`;
        }
        if (hasCE) {
            res += `, č.e.${cisloEvidencni}`;
        }

        res += `${res.length > 1 && !!psc ? ', ' : ''} ${nullAsEmpty(psc)}`;
        if (isStat) {
            res += `, ${nullAsEmpty(stat.reduce((sum, acc) => (acc.klic === adresa?.stat ? acc.dlouheOznaceni : sum), ''))}`;
        }

        // When we ended with just two spaces, address is empty
        return res === '  ' ? '-' : res;
    }
    return '';
};

/**
 * Funkce pro formátování katastrálního území
 * @param {(ZadostSopWebFormPodDetailAdresa)} adresa
 * @return {string}
 */
export const formatKatastralniUzemi = (adresa: ZadostSopWebFormPodDetailAdresa): string => {
    let res = '';
    res += `${adresa.obec ?? ''}`;
    res += adresa.cisloParcely ? `, č.parcely ${adresa.cisloParcely}` : '';
    res += adresa.katastralniUzemi ? `, k.ú.${adresa.katastralniUzemi}` : '';
    res += `, ${adresa.psc ?? ''}`;
    return res;
};

export const useMatches = (maxWidth = '500px') => useMediaQuery(`(max-width:${maxWidth})`);

export const useTablet = () => {
    const minWidth600 = useMediaQuery('(min-width:600px)');
    const maxWidth1200 = useMatches('1200px');
    return maxWidth1200 && minWidth600;
};

/**
 * It takes a string of HTML and returns a string of HTML that's been sanitized
 * @param {string} html - The HTML string to sanitize.
 */
export const sanitizeHTML = (html: string) => sanitizeHtml(html);

/**
 * Funkce pro formátování katastrálního území
 * @param {(Parcela | ZadostSopWebBlokSpecifikaceUzemiBusiness)} adresa
 * @return {string}
 */
export const formatKodKatastralniUzemi = (adresa: Parcela | ZadostSopWebBlokSpecifikaceUzemiBusiness | undefined): string => {
    // if (adresa && 'kmenoveCislo' in adresa) {
    //     let res = '';

    //     res += `${adresa.mesto ?? ''}`;
    //     res += adresa.cisloParcely ? `, č.parc.${adresa.cisloParcely}` : '';
    //     res += adresa.podCislo ? `/${adresa.podCislo}` : '';
    //     res += adresa.kodKatastralnihoUzemi ? `, k.k.ú.${adresa.kodKatastralnihoUzemi}` : '';
    //     res += adresa.nazevKatastralnihoUzemi ? `, k.ú.${adresa.nazevKatastralnihoUzemi}` : '';
    //     res += adresa.psc ? `, ${adresa.psc}` : '';
    //     return res;
    // }
    if (adresa && 'obec' in adresa) {
        let res = '';

        res += `${adresa.obec ?? ''}`;
        res += adresa.cisloParcely ? `, č.parc.${adresa.cisloParcely}` : '';
        res += adresa.podcislo ? `/${adresa.podcislo}` : '';
        res += adresa.kodKatastralnihoUzemi ? `, k.k.ú.${adresa.kodKatastralnihoUzemi}` : '';
        res += adresa.nazevKatastralnihoUzemi ? `, k.ú.${adresa.nazevKatastralnihoUzemi}` : '';
        res += adresa.psc ? `, ${adresa.psc}` : '';
        return res;
    }

    return '';
};

/**
 * Funkce pro striktní porovnání hodnot v memoizované komponentě
 * @template T
 * @param {T} prevProps
 * @param {T} nextProps
 * @return {boolean}
 */
export const areEqual = <T extends Record<string, unknown>>(prevProps: T, nextProps: T): boolean => {
    const [prev, next] = [prevProps, nextProps].map((props) => omitBy(props, isFunction));
    return isEqual(prev, next);
};

const pathElectricity = {
    '/': 'EG.D > Rozcestník',
    '/rozcestnik': 'EG.D > Vstupní obrazovka',
    '/osobniUdaje': 'EG.D > Osobní údaje',
    '/specifikaceUzemi': 'EG.D > Specifikace území',
    '/geoportal': 'EG.D > Geoportál',
    '/technickaSpecifikaceOdberu': 'EG.D > Technická specifikace odběru',
    '/upresneniPredchozihoOdberu': 'EG.D > Úprava předchozího odběru',
    '/technickaSpecifikaceVyrobny': 'EG.D > Technická specifikace výrobny',
    '/souhrnZadosti': 'EG.D > Souhrn žádosti',
    '/podekovani': 'EG.D > Poděkování',
    '/_error': 'EG.D > Nenalezeno',
} as {[key: string]: string};
const pathGas = {
    '/rozcestnik': 'Gas Distribution > Vstupní obrazovka',
    '/osobniUdaje': 'Gas Distribution > Osobní údaje',
    '/specifikaceUzemi': 'Gas Distribution > Specifikace území',
    '/geoportal': 'Gas Distribution > Geoportál',
    '/technickaSpecifikaceOdberu': 'Gas Distribution > Technická specifikace odběru',
    '/upresneniPredchozihoOdberu': 'Gas Distribution > Úprava předchozího odběru',
    '/technickaSpecifikaceVyrobny': 'Gas Distribution > Technická specifikace výrobny',
    '/souhrnZadosti': 'Gas Distribution > Souhrn žádosti',
    '/podekovani': 'Gas Distribution > Poděkování',
    '/_error': 'Gas Distribution > Nenalezeno',
} as {[key: string]: string};

/**
 * Funkce pro formátování cesty pro AppBar
 * @param {string} pathname
 */
export const transformPath = (pathname: string, komodita: Komodita | null | undefined) => {
    const path = komodita === Komodita.ELEKTRINA ? pathElectricity : pathGas;
    return !komodita ? '' : path[pathname]?.replace(/([a-zA-Za-žA-Ž ]+)[.!?]?\s*$/, '<strong>$1</strong>');
};
// funkce hledá diplicitní stringy
export const toFindDuplicates = <T extends string[]>(array?: T) => array?.filter((item, index) => array.indexOf(item) !== index);
export const splitter = <T extends string>(value?: Maybe<T> | T, separator = '.') => value?.split(separator)[0] ?? '';

/**
 * Checking if the input string date is valid or not
 * @param dateInput {string} ["29-02-2000"]
 */
export const validateDate = (dateInput = '') => {
    if (!dateInput) {
        // invalid date if dateInput is empty
        return false;
    }
    if (dateInput?.length < 10) {
        // invalid date if dateInput length is less than 10
        return true;
    }
    // splitter will split the date and get the date seperator e.g.,: -
    const splitter = dateInput.replace(/[0-9]/g, '')[0] ?? '-';
    // using splitter will get the parts of the date
    const parts = dateInput.split(splitter);

    // since year can be in front of yyyy-mm-dd and in last for dd-mm-yyyy taking care of that logic
    const year = parts[0]?.length === 4 ? parts[0] : parts[2];
    // month will always be in the center
    const month = parts[1] ?? '';
    // taking care of day for the different formats like yyyy-mm-dd or dd-mm-yyyy
    const day = parts[0]?.length === 4 ? parts[2] : parts[0];

    // creating date our of the year, month day
    const date = new Date(Number(year), +month - 1, Number(day));

    //validates leapyear and dates exceeding the month limit
    const isValidDate = Boolean(+date) && date.getDate() === Number(day);

    // isValid date is true if the date is valid else false
    return isValidDate;
};

/**
 * Format street line from the address
 *
 * @param {ZadostSopZakaznikAdresa} adresa ZadostSopZakaznikAdresa
 */
export const formatStreetLine = (adresa: ZadostSopZakaznikAdresa | undefined) => {
    let res = adresa?.ulice ?? '';

    if (adresa?.cisloOrientacni) {
        res +=
            typeof adresa?.cisloPopisne === 'string' && adresa?.cisloPopisne.length > 0
                ? ` č.p.${adresa?.cisloPopisne ?? ''}/č.o.${adresa?.cisloOrientacni ?? ''}`
                : ` č.o.${adresa?.cisloOrientacni ?? ''}`;
    } else if (typeof adresa?.cisloPopisne === 'string' && adresa?.cisloPopisne.length > 0) {
        res += ` č.p.${adresa?.cisloPopisne ?? ''}`;
    }

    if (adresa?.cisloEvidencni) {
        res += ` č.e.${adresa?.cisloEvidencni ?? ''}`;
    }

    return res;
};

export const removeNullValuesFromObject = <T extends any | undefined>(object: T) => {
    return Object.fromEntries(Object.entries(object ?? {}).filter(([, v]) => v != null));
};

export const remapZadostSopWebFormType = (typ: ZadostSopWebFormType | undefined) => {
    const map = {
        [ZadostSopWebFormType.ELEKTRINA_NOVY_KRATKODOBY_ODBER_NN]: {
            typZadosti: ZadostSopWebTypZadosti.NOVY_ODBER,
            hladinaNapeti: ZadostElektrinaSopHladinaNapeti.NN,
            typNapeti: ZadostElektrinaSopTypNapeti.KRATKODOBY_ODBER,
            komodita: Komodita.ELEKTRINA,
        },
        [ZadostSopWebFormType.ELEKTRINA_NOVY_KRATKODOBY_ODBER_VN]: {
            typZadosti: ZadostSopWebTypZadosti.NOVY_ODBER,
            hladinaNapeti: ZadostElektrinaSopHladinaNapeti.VN,
            typNapeti: ZadostElektrinaSopTypNapeti.KRATKODOBY_ODBER,
            komodita: Komodita.ELEKTRINA,
        },
        [ZadostSopWebFormType.ELEKTRINA_NOVY_TRVALY_ODBER_NN]: {
            typZadosti: ZadostSopWebTypZadosti.NOVY_ODBER,
            hladinaNapeti: ZadostElektrinaSopHladinaNapeti.NN,
            typNapeti: ZadostElektrinaSopTypNapeti.TRVALY_ODBER,
            komodita: Komodita.ELEKTRINA,
        },
        [ZadostSopWebFormType.ELEKTRINA_NOVY_TRVALY_ODBER_VN]: {
            typZadosti: ZadostSopWebTypZadosti.NOVY_ODBER,
            hladinaNapeti: ZadostElektrinaSopHladinaNapeti.VN,
            typNapeti: ZadostElektrinaSopTypNapeti.TRVALY_ODBER,
            komodita: Komodita.ELEKTRINA,
        },
        [ZadostSopWebFormType.ELEKTRINA_NOVY_TRVALY_ODBER_PLANOVANY]: {
            typZadosti: ZadostSopWebTypZadosti.NOVY_ODBER,
            hladinaNapeti: ZadostElektrinaSopHladinaNapeti.NN,
            typNapeti: ZadostElektrinaSopTypNapeti.PLANOVANY_ZDROJ,
            komodita: Komodita.ELEKTRINA,
        },
        [ZadostSopWebFormType.ELEKTRINA_ZMENA_ODBERU_PRIPOJENI_ZDROJE]: {
            typZadosti: ZadostSopWebTypZadosti.ZMENA_STAVAJICIHO_ODBERU,
            typZmenyNaOdbernemMiste: ZadostElektrinaSopZmenaNaOdbernemMiste.PRIPOJENI_ZDROJE,
            komodita: Komodita.ELEKTRINA,
        },
        [ZadostSopWebFormType.ELEKTRINA_ZMENA_ODBERU_ZJEDNODUSENE_PRIPOJENI]: {
            typZadosti: ZadostSopWebTypZadosti.ZMENA_STAVAJICIHO_ODBERU,
            typZmenyNaOdbernemMiste: ZadostElektrinaSopZmenaNaOdbernemMiste.ZJEDNODUSENE_PRIPOJENI,
            komodita: Komodita.ELEKTRINA,
        },
        [ZadostSopWebFormType.ELEKTRINA_ZMENA_ODBERU_ZMENA_CHARAKTERU]: {
            typZadosti: ZadostSopWebTypZadosti.ZMENA_STAVAJICIHO_ODBERU,
            typZmenyNaOdbernemMiste: ZadostElektrinaSopZmenaNaOdbernemMiste.ZMENA_CHARAKTERU,
            komodita: Komodita.ELEKTRINA,
        },
        [ZadostSopWebFormType.PLYN_NOVY_ODBER]: {
            typZadosti: ZadostSopWebTypZadosti.NOVY_ODBER,
            komodita: Komodita.PLYN,
        },
    } as Record<ZadostSopWebFormType, Omit<ZadostSopWebBlokRozcestnikInput, 'email'>>;
    return map[typ ?? ZadostSopWebFormType.ELEKTRINA_NOVY_KRATKODOBY_ODBER_NN];
};

/**
 * Redirect correctly to given path
 *
 * @param path Path to redirect to
 * @param res Express response
 */
export const redirect = async <T extends Partial<BaseRouter> | string, U extends NextApiResponse>(routerParams: T, res?: U) => {
    if (res) {
        res.writeHead(302, {Location: typeof routerParams === 'string' ? routerParams : routerParams?.pathname});
        res.end();
        res.writableFinished;
    } else {
        await Router.push(routerParams);
    }
};

export const getQuerySelector = (selector: string) => {
    return document.querySelectorAll(selector);
};

export const isOdstavka = (formKind: FormKind | undefined, komodita: Komodita | undefined | null, typZadosti?: ZadostSopWebTypZadosti | undefined) => {
    const isAnyOdstavka = Object.keys(formKind ?? {}).length > 0;
    const odstavkaElektrinaNovyOdber =
        komodita === Komodita.ELEKTRINA &&
        typZadosti === ZadostSopWebTypZadosti.NOVY_ODBER &&
        isAnyOdstavka &&
        isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_ELEKTRINA_SOP_NOVY_ODBER?.aktivni);
    const odstavkaPlynNovyOdber = komodita === Komodita.PLYN && isAnyOdstavka && isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_PLYN_SOP_NOVY_ODBER?.aktivni);
    const odstavkaElektrinaZmenaOdberu =
        komodita === Komodita.ELEKTRINA &&
        typZadosti === ZadostSopWebTypZadosti.ZMENA_STAVAJICIHO_ODBERU &&
        isAnyOdstavka &&
        isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_SOP_ZMENA_ODBERU?.aktivni);
    const odstavka = odstavkaElektrinaNovyOdber || odstavkaPlynNovyOdber || odstavkaElektrinaZmenaOdberu;
    const odstavkaAll =
        isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_ELEKTRINA_SOP_NOVY_ODBER?.aktivni) &&
        isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_SOP_ZMENA_ODBERU?.aktivni) &&
        isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_PLYN_SOP_NOVY_ODBER?.aktivni);
    const odstavkaOnlyElektrina =
        isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_ELEKTRINA_SOP_NOVY_ODBER?.aktivni) &&
        isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_SOP_ZMENA_ODBERU?.aktivni);
    const odstavkaPlyn = isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_PLYN_SOP_NOVY_ODBER?.aktivni);
    const odstavkaMessage = odstavkaElektrinaNovyOdber
        ? formKind?.ZADOST_ELEKTRINA_SOP_NOVY_ODBER?.formZakazanHtml
        : odstavkaPlynNovyOdber
          ? formKind?.ZADOST_PLYN_SOP_NOVY_ODBER?.formZakazanHtml
          : odstavkaElektrinaZmenaOdberu
            ? formKind?.ZADOST_SOP_ZMENA_ODBERU?.formZakazanHtml
            : formKind?.ZADOST_ELEKTRINA_SOP_NOVY_ODBER?.formZakazanHtml;
    return {odstavka, odstavkaMessage, odstavkaAll, odstavkaOnlyElektrina, odstavkaPlyn};
};
