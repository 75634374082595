import {useLazyQuery} from '@apollo/client';
import {Query, ZadostSopWebBlokType, ZadostSopWebQueryFormInfoArgs} from '@eon.cz/apollo13-graphql-web';
import {AddOutlined} from '@mui/icons-material';
import {useRouter} from 'next/router';
import {FC, useCallback, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useCommonAction} from '../../../common/Actions/CommonAction';
import {PenIcon} from '../../../common/components/icons/PenIcon';
import {useAddNotification} from '../../../common/components/notifications/actions/NotificationsActions';
import {NotificationType} from '../../../common/components/notifications/model/NotificationModel';
import {Div} from '../../../common/components/styled/Div';
import {CommonQueries} from '../../../common/graphql/CommonQueries';
import {STYLE} from '../../../common/styles';
import {cestaDoMesta, cestaZMesta} from '../../../common/utils/CommonUtils';
import {StoreType} from '../../../lib/StoreType';
import {theme} from '../../../lib/theme';

type Props = {
    readonly setShowStepper: (value: boolean) => void;
    readonly parseId: string | undefined;
};

// Komponenta pro výběr v pokračování žádosti či ne
export const RozcestnikNewOrContinue: FC<Props> = ({setShowStepper, parseId}) => {
    const {reset, setFormStepPath} = useCommonAction();
    const form = useFormContext();
    const {push} = useRouter();
    const {formatMessage} = useIntl();
    const {addNotification} = useAddNotification();
    const {lastFinishedBlok, selfcare} = useSelector((state: StoreType) => state.common);

    const [loadForm] = useLazyQuery<Query, ZadostSopWebQueryFormInfoArgs & {callGeoPortal: boolean}>(CommonQueries.gql.info, {
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            const form = res.sopWeb.formInfo.form;

            if (form?.lastFinishedBlok?.typ === ZadostSopWebBlokType.GEOPORTAL) {
                const isGeoportalComplete = res?.sopWeb?.geoPortalBlok?.completed;
                const direction = isGeoportalComplete ? 'right' : 'left';
                const pathname = cestaZMesta(res?.sopWeb?.formInfo?.form?.formStepPath, 3, direction);
                push({pathname}, undefined, {shallow: true}).then(
                    () => !isGeoportalComplete && addNotification({type: NotificationType.WARNING, text: <FormattedMessage id="geoportal.complete.fail" />}),
                );
                return;
            }
            const pathname = cestaDoMesta(form?.formStepPath, form?.lastFinishedBlok);
            setFormStepPath(form?.formStepPath);
            push({pathname}, undefined, {shallow: true});
        },
    });

    const handleClickContinue = useCallback(() => {
        loadForm({variables: {parseId, callGeoPortal: lastFinishedBlok?.typ === ZadostSopWebBlokType.GEOPORTAL}});
    }, [lastFinishedBlok?.typ, loadForm, parseId]);

    useEffect(() => {
        if (selfcare && lastFinishedBlok?.typ) {
            handleClickContinue();
        }
    }, [handleClickContinue, lastFinishedBlok?.typ, selfcare]);

    const handleClickNew = () => {
        reset();
        form?.reset();
        setShowStepper(true);
    };

    return (
        <>
            {selfcare ? (
                <div />
            ) : (
                <Div sx={{...STYLE.buttonBase, justifyContent: 'center', marginLeft: -8}}>
                    <Div
                        tabIndex={0}
                        role="button"
                        sx={{
                            padding: '10px 20px',
                            borderStyle: 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: 'fit-content',
                            height: 'fit-content',
                            borderRadius: '10px',
                            borderWidth: '2px',
                            cursor: 'pointer',
                            marginRight: 2,
                            '&:hover': {
                                backgroundColor: `${theme.palette.primary.main}26`,
                            },
                        }}
                        onClick={handleClickNew}
                        onKeyUp={handleClickNew}
                    >
                        <AddOutlined />
                        {formatMessage({id: 'button.add.new'})}
                    </Div>
                    <Div
                        role="button"
                        tabIndex={-1}
                        sx={{
                            padding: '10px 20px',
                            borderStyle: 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: 'fit-content',
                            height: 'fit-content',
                            borderRadius: '10px',
                            borderWidth: '2px',
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: `${theme.palette.primary.main}26`,
                            },
                        }}
                        onClick={handleClickContinue}
                        onKeyUp={handleClickContinue}
                    >
                        <PenIcon />
                        {formatMessage({id: 'button.edit.new'})}
                    </Div>
                </Div>
            )}
        </>
    );
};
