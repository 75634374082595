import {Komodita} from '@eon.cz/apollo13-graphql-web';
import {ErrorOutlineOutlined} from '@mui/icons-material';
import {Button, Typography} from '@mui/material';
import {useRouter} from 'next/router';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {StoreType} from '../../../lib/StoreType';
import {useCommonAction} from '../../Actions/CommonAction';
import {PageRoute} from '../../PageRouteModel';
import {sanitizeHTML} from '../../utils/CommonUtils';
import {Div} from '../styled/Div';

type Props = {
    readonly message: string | undefined;
    readonly refetch: () => void;
};

/**
 * Komponenta pro zobrazení informace při vypnutí formuláře z admin portálu
 *
 * @param {*} {message, refetch}
 */
export const FormOff: FC<Props> = ({message, refetch}) => {
    const {push} = useRouter();
    const {setActiveSteps} = useCommonAction();
    const komodita = useSelector((state: StoreType) => state.common.komodita);

    /**
     * Přesměruje uživatele na hlavní stránku (root)
     */
    const handleRootRedirect = () => {
        push({pathname: komodita === Komodita.ELEKTRINA ? PageRoute.ROOT : PageRoute.VSTUP}, undefined, {shallow: true}).then(() => {
            const step = komodita === Komodita.ELEKTRINA ? undefined : 0;
            setActiveSteps(step);
        });
    };
    const handleRefresh = () => refetch();
    return (
        <Div
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 'auto',
                gap: 2,
                mt: {xs: 0, md: 10},
            }}
        >
            <ErrorOutlineOutlined style={{fontSize: 150}} color="error" />
            <Div
                sx={{
                    padding: 0,
                }}
            >
                <Typography variant="h6" color="inherit" dangerouslySetInnerHTML={{__html: sanitizeHTML(message ?? '')}} />
            </Div>
            <Div
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    gap: 2,
                    width: {xs: '100%', md: '30%'},
                }}
            >
                <Button
                    size="medium"
                    color="primary"
                    sx={{
                        marginRight: 2,
                    }}
                    variant="contained"
                    fullWidth
                    onClick={handleRootRedirect}
                >
                    <FormattedMessage id="button.root" />
                </Button>
                <Button size="medium" color="primary" variant="contained" fullWidth onClick={handleRefresh}>
                    <FormattedMessage id="button.aktualizovat" />
                </Button>
            </Div>
        </Div>
    );
};
