import {useMutation} from '@apollo/client';
import {Komodita, Mutation, ZadostSopWebMutationResolveFormInfoArgs, ZadostSopWebTypZadosti} from '@eon.cz/apollo13-graphql-web';
import {WhatshotOutlined} from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {useCommonAction} from '../../../common/Actions/CommonAction';
import {CustomButtonStyle} from '../../../common/components/buttons/CustomButtonStyle';
import {LoadingDialog} from '../../../common/components/dialog/LoadingDialog';
import {ElectricityIcon} from '../../../common/components/icons/ElectricityIcon';
import {EgdLogo} from '../../../common/components/layout/EgdLogo';
import {GasLogo} from '../../../common/components/layout/GasLogo';
import {PaperBlock} from '../../../common/components/layout/PaperBlock';
import {Div} from '../../../common/components/styled/Div';
import {CommonMutations} from '../../../common/graphql/CommonMutations';
import {PageRoute} from '../../../common/PageRouteModel';
import {changeFavicon, isNotNullOrUndefinedOrEmpty, isOdstavka, useMatches} from '../../../common/utils/CommonUtils';
import {StoreType} from '../../../lib/StoreType';
import {theme} from '../../../lib/theme';
import {useRozcestnikAction} from '../action/RozcestnikAction';
import {RozcestnikElektrina} from './RozcestnikElektrina';
import {RozcestnikNewOrContinue} from './RozcestnikNewOrContinue';

type Props = {
    queryKomodita: Komodita;
};

/**
 * Komponenta pro zobrazení úvodního rozcestníku
 */
export const Rozcestnik = ({queryKomodita}: Props) => {
    // redux state
    const komodita = useSelector((state: StoreType) => state.common.komodita);
    const parseId = useSelector((state: StoreType) => state.common.parseId);
    const formKind = useSelector((state: StoreType) => state.common.formKind);
    const {odstavkaPlyn, odstavkaOnlyElektrina} = isOdstavka(formKind, komodita);

    // local state
    const [pending, setPending] = useState(false);
    const [showStepper, setShowStepper] = useState(false);

    // methods
    const {push} = useRouter();
    const {setKomoditaAction, setRozcestnikData} = useRozcestnikAction();
    const {setFormStepPath, resetStore} = useCommonAction();
    const matches = useMatches();

    //local variables
    const width = matches ? 100 : 250;
    const height = matches ? 50 : 120;

    // muattions
    const [sendRezcestnik, {loading}] = useMutation<Mutation, ZadostSopWebMutationResolveFormInfoArgs>(CommonMutations.gql.resolveFormInfo, {
        onCompleted: (res) => {
            const {expectedFormStepPath} = res?.sopWeb?.resolveFormInfo;
            setFormStepPath(expectedFormStepPath);
            push({pathname: PageRoute.VSTUP}, undefined, {shallow: true});
        },
        onError: () => {
            setPending(false);
        },
    });

    const handlePlyn = () => {
        push({pathname: PageRoute.VSTUP}).then(() => {
            sendRezcestnik({variables: {input: {komodita: Komodita.PLYN, typZadosti: ZadostSopWebTypZadosti.NOVY_ODBER}}});
            setRozcestnikData({komodita: Komodita.PLYN, typZadosti: ZadostSopWebTypZadosti.NOVY_ODBER});
        });
    };

    const handleShowStepper = () => setShowStepper(true);

    const handleChangeKomodita = (komodita: Komodita) => {
        setKomoditaAction(komodita);
        if (komodita === Komodita.PLYN) {
            setPending(true);
            handlePlyn();
        }

        // nastaveni komodity do cookie
        changeFavicon(komodita);
    };

    useEffect(() => {
        if (!parseId) {
            setShowStepper(true);
        }
    }, [parseId]);

    useEffect(() => {
        if (komodita !== queryKomodita && !!komodita && !!queryKomodita) {
            resetStore();
            setKomoditaAction(queryKomodita);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [komodita, queryKomodita]);

    if (loading || pending) {
        return <LoadingDialog open />;
    }

    if ((komodita === Komodita.ELEKTRINA || queryKomodita === Komodita.ELEKTRINA) && showStepper) {
        changeFavicon(Komodita.ELEKTRINA);
        setKomoditaAction(Komodita.ELEKTRINA);
        return <RozcestnikElektrina />;
    }
    if ((komodita === Komodita.PLYN || queryKomodita === Komodita.PLYN) && showStepper) {
        changeFavicon(Komodita.PLYN);
        setKomoditaAction(Komodita.PLYN);
        handlePlyn();
        return;
    }
    if (!komodita) {
        document.cookie = `komodita=; path=/`;
    }

    return (
        <PaperBlock
            titleBlock={<FormattedMessage id="formular.nove.pripojeni.bez.komodity" />}
            description={
                <FormattedMessage
                    id={showStepper ? 'formular.nove.pripojeni.bez.komodity.description' : 'formular.nove.pripojeni.elektrina.exist.title.description'}
                />
            }
            centerTitle
        >
            {!showStepper && <RozcestnikNewOrContinue parseId={parseId} setShowStepper={handleShowStepper} />}
            {showStepper && (
                <Grid container>
                    <Grid size={{xs: 12}} sx={{display: 'flex', justifyContent: 'center'}}>
                        <CustomButtonStyle
                            value={Komodita.ELEKTRINA}
                            icon={
                                <Div>
                                    <ElectricityIcon
                                        width={width / 1.1}
                                        height={height / 1.1}
                                        logoColor={odstavkaOnlyElektrina ? theme.palette.grey[500] : undefined}
                                    />
                                    <EgdLogo
                                        width={width}
                                        height={height}
                                        pathFill={odstavkaOnlyElektrina ? theme.palette.grey[500] : theme.palette.error.main}
                                    />
                                </Div>
                            }
                            disabled={odstavkaOnlyElektrina}
                            changeLocalValue={handleChangeKomodita}
                            fitContent
                        />
                        <CustomButtonStyle
                            value={Komodita.PLYN}
                            icon={
                                <Div>
                                    <WhatshotOutlined
                                        sx={{width: width / 1.1, height: height / 1.1, color: odstavkaPlyn ? theme.palette.grey[500] : undefined}}
                                    />
                                    <GasLogo
                                        width={width}
                                        height={height}
                                        pathFill={formKind?.ZADOST_PLYN_SOP_NOVY_ODBER ? theme.palette.grey[500] : 'inherit'}
                                    />
                                </Div>
                            }
                            changeLocalValue={handleChangeKomodita}
                            disabled={isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_PLYN_SOP_NOVY_ODBER?.aktivni)}
                            fitContent
                        />
                    </Grid>
                </Grid>
            )}
        </PaperBlock>
    );
};
