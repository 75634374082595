import {PaperProps, Typography, useMediaQuery} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {useTheme} from '../../../lib/theme';
import {Span} from '../styled/Span';

export type PaperBlockProps = PaperProps & {
    readonly titleBlock: string | JSX.Element | undefined;
    readonly description?: string | JSX.Element;
    readonly titleBlockIcon?: JSX.Element | string;
    readonly centerTitle?: boolean;
};

export const PaperBlock: FC<PaperBlockProps> = ({children, titleBlock, titleBlockIcon, description, centerTitle}) => {
    const matches = useMediaQuery('(max-width:500px)');
    const {theme} = useTheme();
    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 12}} sx={{textAlign: centerTitle ? 'center' : undefined}}>
                <Span
                    sx={
                        centerTitle
                            ? {
                                  display: 'contents',
                                  textAlign: 'center',
                              }
                            : {
                                  display: 'flex',
                                  alignItems: 'flex-start',
                              }
                    }
                >
                    {titleBlockIcon}
                    {titleBlock && (
                        <Typography
                            variant={matches ? 'h5' : 'h4'}
                            sx={{
                                marginLeft: 0,
                                whiteSpace: 'nowrap',
                                fontWeight: 'bold',
                            }}
                        >
                            {typeof titleBlock === 'string' ? <FormattedMessage id={titleBlock} /> : titleBlock}
                        </Typography>
                    )}
                </Span>
                {description && (
                    <Typography
                        variant="subtitle1"
                        sx={{
                            marginLeft: centerTitle ? undefined : matches && centerTitle ? 0 : 6.5,
                        }}
                    >
                        {typeof description === 'string' ? <FormattedMessage id={description} /> : description}
                    </Typography>
                )}
            </Grid>

            <Grid size={{xs: 12}} style={{margin: theme.spacing(matches ? 3 : 10, 0, matches ? 3 : 10, matches || centerTitle ? 0 : 7)}}>
                {children}
            </Grid>
        </Grid>
    );
};
