import {theme} from '../lib/theme';

export const STYLE = {
    buttonBase: {
        display: 'flex',
    } as const,
    error: {
        color: theme.palette.error.main,
    } as const,
    ares: {
        textAlign: 'center',
        marginTop: 1,
    } as const,
};
