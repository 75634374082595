import {ApolloProvider} from '@apollo/client';
import {EmotionCache} from '@emotion/cache';
import {AppCacheProvider} from '@mui/material-nextjs/v14-pagesRouter';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {cs} from 'date-fns/locale/cs';
import App, {AppProps} from 'next/app';
import Head from 'next/head';
import {useEffect, useMemo} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {CustomFormats, RawIntlProvider, createIntl, createIntlCache} from 'react-intl';
import {Provider} from 'react-redux';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import {ErrorFallback} from '../client/common/components/ErrorFallback';
import {browserSupoort} from '../client/common/constants';
import {redirect} from '../client/common/utils/CommonUtils';
import {useApollo} from '../client/lib/apolloClient';
import {AppContextProvider} from '../client/lib/context/AppContext';
import store from '../client/lib/store';
import {useTheme} from '../client/lib/theme';
import Czech from '../lang/cs.json';
import English from '../lang/en.json';

/* Root stylesheet */
import {Komodita} from '@eon.cz/apollo13-graphql-web';
import {DynamicFavicon} from '../client/common/components/DynamicFavicon';
import '../public/static/fonts/BrixSans/stylesheet.css';

// This is optional but highly recommended
// since it prevents memory leak
const intlCache = createIntlCache();

type Props = AppProps & {
    readonly locale: any;
    readonly formats: CustomFormats | undefined;
    readonly statusCode: number;
    readonly emotionCache?: EmotionCache;
    readonly komodita: Komodita;
};

const MyApp = (props: Props) => {
    const {Component, pageProps, locale, formats, statusCode, komodita} = props;

    const [shortLocale] = locale ? locale.split('-') : ['en'];

    const messages = useMemo(() => {
        switch (shortLocale) {
            case 'cs':
                return Czech;
            case 'en':
                return English;
            default:
                return Czech;
        }
    }, [shortLocale]);

    const persistor = persistStore(store, {}, () => {
        persistor.persist();
    });

    const {theme} = useTheme();

    const apolloClient = useApollo(store.dispatch);

    useEffect(() => {
        if (!browserSupoort(window.navigator.userAgent)) {
            redirect({pathname: '/warning.html'});
        }
    }, []);

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ApolloProvider client={apolloClient}>
                    <RawIntlProvider value={createIntl({locale, messages, formats}, intlCache)}>
                        <AppCacheProvider {...props}>
                            <ThemeProvider theme={theme}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={cs}>
                                    <CssBaseline />
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <Head>
                                            <meta
                                                name="viewport"
                                                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                                            />
                                            {/* eslint-disable-next-line i18next/no-literal-string */}
                                            <title>Žádost o připojení</title>
                                        </Head>
                                        <DynamicFavicon komodita={komodita} />
                                        <AppContextProvider>
                                            <Component {...pageProps} statusCode={statusCode} />
                                        </AppContextProvider>
                                    </ErrorBoundary>
                                </LocalizationProvider>
                            </ThemeProvider>
                        </AppCacheProvider>
                    </RawIntlProvider>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    );
};

const getInitialProps: typeof App.getInitialProps = async (appContext) => {
    const {ctx} = appContext;
    const komodita =
        ctx.req?.headers.cookie
            ?.split('; ')
            .find((row) => row.startsWith('komodita='))
            ?.split('=')[1] ?? '';

    const [appProps] = await Promise.all([App.getInitialProps(appContext)]);

    const formats = getFormats();

    const statusCode = appContext?.ctx?.res?.statusCode;

    return {
        ...appProps,
        locale: getLocales(ctx?.req?.headers['accept-language']),
        formats,
        statusCode,
        komodita,
    };
};

const getLocales = (str: string | undefined) => {
    return str?.split(',').map((type) => type.split(';')[0]?.trim().replace('*', ''))[0] ?? 'cs';
};

const getFormats = () => ({
    number: {
        CS: {
            style: 'currency',
            currency: 'CZK',
        },
        EN: {
            style: 'currency',
            currency: 'US',
        },
    },
});

MyApp.getInitialProps = getInitialProps;

export default MyApp;
