import {useQuery} from '@apollo/client';
import {Komodita, Query, SopWebFormOutage, ZadostSopWebBlokType, ZadostSopWebQueryFormInfoArgs, ZadostSopWebTypZadosti} from '@eon.cz/apollo13-graphql-web';
import {ArrowBackOutlined} from '@mui/icons-material';
import {AppBar, Box, Button, IconButton, Step, StepLabel, Stepper, Theme, Toolbar, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import invert from 'lodash/invert';
import isNumber from 'lodash/isNumber';
import {useRouter} from 'next/router';
import {FC, ReactNode, useEffect, useLayoutEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useRozcestnikAction} from '../../../components/rozcestnik/action/RozcestnikAction';
import {StoreType} from '../../../lib/StoreType';
import {apolloClient} from '../../../lib/apolloClient';
import {useAppContext} from '../../../lib/context/AppContext';
import {theme} from '../../../lib/theme';
import {useCommonAction} from '../../Actions/CommonAction';
import {PageRoute} from '../../PageRouteModel';
import {formMapPath} from '../../constants';
import {CommonQueries} from '../../graphql/CommonQueries';
import {FormKind} from '../../store/CommonStore';
import {convertPovoleneTypyPriloh, isOdstavka, remapZadostSopWebFormType, transformPath, useMatches, useTablet} from '../../utils/CommonUtils';
import {LoadingDialog} from '../dialog/LoadingDialog';
import {useAddNotification} from '../notifications/actions/NotificationsActions';
import {NotificationsComponent} from '../notifications/components/NotificationsComponent';
import {NotificationType} from '../notifications/model/NotificationModel';
import {Div} from '../styled/Div';
import {EgdLogo} from './EgdLogo';
import {FormOff} from './FormOff';
import {GasLogo} from './GasLogo';

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        marginLeft: 30,
    },
    topBar: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        maxWidth: 1450,
        margin: '0 auto',
        paddingLeft: 15,
    },
    top: {
        height: 80,
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 20,
        margin: '0 auto',
        maxWidth: 1680,
    },
    button: {
        textTransform: 'none',
    },
    main: {
        display: 'block',
        padding: '82px 16px',
        maxWidth: 1450,
        margin: 'auto',
        height: '100%',
    },
    wrapperFooter: {
        width: '100%',
        height: 86,
        left: 0,
        bottom: 0,
        position: 'absolute',
        backgroundColor: theme.palette.secondary.main,
        background: 'linear-gradient(270deg,rgba(225,48,25,0.86) 0%,rgba(225,48,25,0.27) 100%)',
    },
    footer: {
        paddingTop: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 20,
        maxWidth: 1680,
        margin: 'auto',
    },
    whiteText: {
        color: theme.palette.background.default,
    },
    stepper: {
        backgroundColor: theme.palette.background.default,
        marginTop: 20,
    },
    inactive: {
        color: theme.palette.primary.main,
    },
    body: {
        backgroundColor: theme.palette.background.default,
        position: 'relative',
        minHeight: '100vh',
    },
}));

type Props = {
    readonly statusCode: number;
    readonly children: ReactNode;
};

export const PageLayout: FC<Props> = ({children, statusCode}) => {
    // methods
    const classes = useStyles();
    const {push, pathname} = useRouter();
    const matches = useMatches();
    const tablet = useTablet();
    const {formatMessage} = useIntl();
    const {closeNotification, addNotification} = useAddNotification();
    const {setActiveSteps, setFormStepPath, setFormType, setLastFinishedBlok, setFormKind, backToD24, setTypZakaznika, setEndOfForm, setParseId, reset} =
        useCommonAction();
    const {setRozcestnikData} = useRozcestnikAction();
    const {setContextValues} = useAppContext();

    // redux state
    const {notifications} = useSelector((state: StoreType) => state.notifications);
    const {activeStep, parseId, formStepPath, endOfForm, selfcare, komodita, formKind} = useSelector((state: StoreType) => state.common);
    const {typZadosti, typZmenyNaOdbernemMiste, skupinaData} = useSelector((state: StoreType) => state.rozcestnik);
    const [showChildren, setShowChildren] = useState(() => !parseId || false);

    // local variables
    const path = transformPath(pathname, komodita);

    // Proměnná pro zobrazení stepperu a rozcestníku v Appbaru
    const showComponent = !([404, 500].includes(statusCode) || !isNumber(activeStep) || pathname === '/error');

    // queries
    const {loading: loadingFormOff, refetch: refetchFormOff} = useQuery<Query>(CommonQueries.gql.formOff, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (res) => {
            const sopWebFormOutages = res?.sopWeb?.nastaveni?.sopWebFormOutages;
            const formOff = sopWebFormOutages?.reduce((sum, acc) => {
                if (!sum?.[acc.formKind]) {
                    sum[acc.formKind] = {} as SopWebFormOutage;
                }
                sum[acc.formKind] = acc;
                return sum;
            }, {} as FormKind);
            setFormKind(formOff);
        },
    });

    const {loading} = useQuery<Query, ZadostSopWebQueryFormInfoArgs & {callGeoPortal: boolean; canCallBlock: boolean}>(CommonQueries.gql.formInfo, {
        fetchPolicy: 'network-only',
        variables: {parseId, callGeoPortal: false, canCallBlock: pathname !== PageRoute.PODEKOVANI && !!selfcare},
        skip: !parseId || pathname === '/_error' || (pathname === '/' && !selfcare),
        onCompleted: (res) => {
            const {found, form} = res.sopWeb.formInfo;
            const rawData = res.sopWeb.rozcestnikBlok;

            // Pokud je žádost formuláře založena
            if (found && Object.keys(form ?? {}).length > 0) {
                const povoleneTypyPriloh = res?.nastaveni?.prilohy?.povoleneTypyPriloh;
                setContextValues({povoleneTypyPriloh: convertPovoleneTypyPriloh(povoleneTypyPriloh)});
                setFormStepPath(form?.formStepPath);
                setFormType(form?.formType);
                setLastFinishedBlok(form?.lastFinishedBlok);
                const lastFinishStep = formStepPath?.findIndex((p) => p === form?.lastFinishedBlok?.typ) ?? 0;
                const userWantsToGo = formStepPath?.findIndex((p) => p === invert(formMapPath)[pathname]) ?? 0;
                if (pathname === PageRoute.PODEKOVANI) {
                    setParseId(undefined);
                    setShowChildren(true);
                    setEndOfForm(true);
                }
                if (selfcare) {
                    const rozcestnikData = remapZadostSopWebFormType(form?.formType);
                    setTypZakaznika(res.sopWeb.zakaznikBlok.typZakaznika);
                    setRozcestnikData({...rozcestnikData, uzivatelData: rawData.uzivatelData, skupinaData: rawData.skupinaData});
                    setShowChildren(true);
                }

                if (
                    pathname !== '/redirectFromGeoPortal' &&
                    ((userWantsToGo === -1 && pathname !== PageRoute.ROOT) || (userWantsToGo - lastFinishStep >= 2 && form?.lastFinishedBlok?.typ !== pathname))
                ) {
                    addNotification({type: NotificationType.ERROR, text: <FormattedMessage id="error.page.pathname" />});
                    const wayToGo =
                        form?.lastFinishedBlok?.typ === ZadostSopWebBlokType.GEOPORTAL ? PageRoute.UPRESNENI_PREDCHOZIHO_ODBERU : form?.lastFinishedBlok?.typ;
                    push(wayToGo ?? PageRoute.ROOT, undefined, {shallow: true}).then(() => setShowChildren(true));
                } else {
                    setShowChildren(true);
                }
            } else {
                // Pokud již parseId není platné, přesuneme uživatele zpět na ROOT a zobrazíme notifikaci
                push(PageRoute.ROOT, undefined, {shallow: true});
                addNotification({type: NotificationType.WARNING, text: <FormattedMessage id="error.page.not.found" />});
            }
        },
    });

    // Jestli je daný typ žádosti vypnut přes Admin portál
    const {odstavka, odstavkaMessage, odstavkaAll} = isOdstavka(formKind, komodita, typZadosti);

    const handleRefresh = () => {
        refetchFormOff();
    };

    // effekt pro zajištění nezobrazení obsahu,který se zobrazit nemá na základě toho, že uživatel přechází na route, která nemá následovat dle podmínek
    useLayoutEffect(() => {
        if (!parseId && pathname !== '/' && pathname !== '/rozcestnik' && !endOfForm && statusCode === 200) {
            setShowChildren(false);
            push({pathname: PageRoute.ROOT});
        }
    }, [endOfForm, parseId, pathname, push, statusCode]);

    useEffect(() => {
        if (formStepPath && formStepPath?.length > 0) {
            const step = formStepPath?.findIndex((p) => p === invert(formMapPath)[pathname]);
            setActiveSteps(step === -1 ? undefined : step);
        }
        if (pathname !== '/podekovani' && endOfForm && !parseId && selfcare) {
            handleBackToSelfcare();
        }
        if (pathname === PageRoute.ROOT) {
            setShowChildren(true);
            setActiveSteps(undefined);
        }
        if (pathname === '/_error') {
            push('/_error');
            setActiveSteps(undefined);
            setShowChildren(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formStepPath, pathname, parseId, endOfForm]);

    // functions
    const handleChangeRoute = () =>
        pathname !== PageRoute.ROOT &&
        push({pathname: PageRoute.ROOT}, undefined, {shallow: true}).then(() => {
            setActiveSteps(undefined);
            reset();
        });

    const handleRouteEGD = () => push('https://www.egd.cz');
    const handleRouteGAS = () => push('https://www.gasd.cz');
    const handleBackToSelfcare = () => backToD24(apolloClient, komodita, skupinaData?.typ, endOfForm);

    return (
        <div className={classes.body}>
            {/* Zobrazení notifikací */}
            <NotificationsComponent notifications={notifications} onClose={closeNotification} />

            {/* Zobrazení horní lišty s logem */}
            <div className={classes.top}>
                {komodita && (
                    <>
                        <IconButton className={classes.logo} onClick={komodita === Komodita.ELEKTRINA ? handleRouteEGD : handleRouteGAS}>
                            {komodita === Komodita.ELEKTRINA && <EgdLogo width={150} height={50} pathFill={theme.palette.error.main} />}
                            {komodita === Komodita.PLYN && <GasLogo width={150} height={50} pathFill={'inherit'} />}
                        </IconButton>
                        <Div>
                            {selfcare && (
                                <Button
                                    sx={(theme) => ({
                                        marginRight: theme.spacing(3),
                                        border: `solid 1px ${theme.palette.primary.main}`,
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.main,
                                            color: theme.palette.common.white,
                                            border: `solid 1px ${theme.palette.secondary.main}`,
                                        },
                                    })}
                                    variant="outlined"
                                    onClick={handleBackToSelfcare}
                                >
                                    <ArrowBackOutlined />
                                    <FormattedMessage id="selfcare" />
                                </Button>
                            )}
                            {!selfcare && (
                                <Button color="primary" variant="contained" onClick={handleChangeRoute}>
                                    <FormattedMessage id="odkaz.egd" />
                                </Button>
                            )}
                        </Div>
                    </>
                )}
            </div>

            {/* Zobrazení appbaru */}
            <AppBar
                position="relative"
                color="secondary"
                sx={(theme) => ({
                    ...(komodita === Komodita.ELEKTRINA && {
                        backgroundImage: "url('/static/images/AppBarBackground.svg')",
                        backgroundPosition: 'right',
                        backgroundRepeat: 'no-repeat',
                        display: 'block',
                        minHeight: 204,
                    }),
                    ...(komodita !== Komodita.ELEKTRINA && {
                        display: !komodita ? 'flex' : 'block',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.secondary.main,
                        minHeight: 204,
                    }),
                    padding: {xs: 1, md: 0},
                })}
            >
                <Toolbar className={classes.topBar}>
                    {komodita && (
                        <Typography
                            variant="body2"
                            color="inherit"
                            sx={{
                                marginTop: 2.5,
                            }}
                            dangerouslySetInnerHTML={{__html: path ?? ''}}
                        />
                    )}

                    <Typography
                        variant={matches ? 'h4' : !komodita && !tablet ? 'h1' : !komodita && tablet ? 'h2' : 'h3'}
                        color="inherit"
                        sx={{marginTop: {xs: 0, md: 2.5}, alignSelf: !komodita ? 'center' : 'auto'}}
                    >
                        <Box fontWeight="fontWeightBold">
                            <FormattedMessage
                                id="smlouva.pripojeni"
                                values={{komodita: komodita === Komodita.ELEKTRINA ? ' - Elektřina' : komodita === Komodita.PLYN ? ' - Plyn' : ''}}
                            />
                        </Box>
                    </Typography>
                    {showComponent && (
                        <Typography variant="body1" color="inherit" sx={{marginTop: 2.5}}>
                            <FormattedMessage
                                id={
                                    typZadosti === ZadostSopWebTypZadosti.NOVY_ODBER
                                        ? 'formular.nove.pripojeni.komodita'
                                        : typZadosti === ZadostSopWebTypZadosti.ZMENA_STAVAJICIHO_ODBERU
                                          ? 'formular.zmena.pripojeni.komodita'
                                          : 'empty'
                                }
                                values={{
                                    komodita: formatMessage({id: `komodita.${komodita?.toLowerCase() ?? 'elektrina'}`}),
                                    zmena: typZmenyNaOdbernemMiste ? formatMessage({id: `rozcestnik.${typZmenyNaOdbernemMiste}.label`}) : undefined,
                                }}
                            />
                        </Typography>
                    )}
                </Toolbar>
            </AppBar>

            {/* Zobrazení stepperu */}
            {showComponent && (
                <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel style={{overflowX: matches ? 'auto' : 'hidden'}}>
                    {formStepPath?.map((label, index) => (
                        <Step key={index} classes={{alternativeLabel: classes.inactive}}>
                            <StepLabel>
                                <FormattedMessage id={`layout.${label?.toUpperCase()}`} />
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            )}

            {/* Zobrazení obsahu */}
            <div role="main" className={classes.main}>
                {(loading || loadingFormOff) && <LoadingDialog open />}
                {showChildren && !(odstavka && pathname !== PageRoute.ROOT) && !odstavkaAll && !loadingFormOff && !loading && children}
                {((odstavka && pathname !== PageRoute.ROOT) || odstavkaAll) && !loadingFormOff && !loading && (
                    <FormOff message={odstavkaMessage} refetch={handleRefresh} />
                )}
            </div>

            {/* Zobrazení patičky */}
            <Div
                sx={{
                    width: '100%',
                    height: 86,
                    left: 0,
                    bottom: 0,
                    position: 'absolute',
                    backgroundColor: 'secondary.main',
                    background: komodita === Komodita.ELEKTRINA ? 'linear-gradient(270deg,rgba(225,48,25,0.86) 0%,#261B62 100%)' : undefined,
                }}
            >
                <Div
                    sx={{
                        paddingTop: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: 2,
                        maxWidth: 1680,
                        margin: 'auto',
                    }}
                >
                    {komodita && (
                        <>
                            {komodita === Komodita.ELEKTRINA && <EgdLogo width={100} height={33} pathFill={theme.palette.background.default} />}
                            {komodita === Komodita.PLYN && <GasLogo width={100} height={33} pathFill={'default'} />}
                            <Typography component={'span'} variant="body2" className={classes.whiteText}>
                                <FormattedMessage
                                    id={komodita === Komodita.ELEKTRINA ? 'footer.electricity' : 'footer.gas'}
                                    values={{year: new Date().getFullYear()}}
                                />
                            </Typography>
                        </>
                    )}
                </Div>
            </Div>
        </div>
    );
};
