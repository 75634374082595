import {useMutation} from '@apollo/client';
import {
    Komodita,
    Mutation,
    ZadostElektrinaSopHladinaNapeti,
    ZadostElektrinaSopTypNapeti,
    ZadostElektrinaSopZmenaNaOdbernemMiste,
    ZadostSopWebBlokRozcestnikInput,
    ZadostSopWebMutationResolveFormInfoArgs,
    ZadostSopWebTypZadosti,
} from '@eon.cz/apollo13-graphql-web';
import {Button, Step, StepContent, StepLabel, Stepper, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useRouter} from 'next/router';
import {FC, ReactNode, useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useCommonAction} from '../../../common/Actions/CommonAction';
import {PageRoute} from '../../../common/PageRouteModel';
import {LoadingDialog} from '../../../common/components/dialog/LoadingDialog';
import {PaperBlock} from '../../../common/components/layout/PaperBlock';
import {CommonMutations} from '../../../common/graphql/CommonMutations';
import {isNotNullOrUndefinedOrEmpty} from '../../../common/utils/CommonUtils';
import {StoreType} from '../../../lib/StoreType';
import {useRozcestnikAction} from '../action/RozcestnikAction';
import {getSteps} from '../service/RozcestnikService';
import {RozcestnikStepContent} from './RozcestnikStepContent';

const useStyles = makeStyles((theme: Theme) => ({
    supportLink: {
        color: theme.palette.primary.main,
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    stepper: {
        backgroundColor: theme.palette.background.default,
    },
    stepIcon: {
        width: 40,
        height: 40,
        // fontSize: '1.2rem',
    },
    step: {
        marginLeft: 20,
        overflow: 'inherit',
    },
    vertical: {
        marginLeft: -8,
    },
    label: {
        fontSize: '1.4rem',
    },
    active: {
        '&.MuiStepLabel-active': {
            fontSize: '1.2rem',
            fontWeight: 900,
        },
    },
}));
/**
 * Komponenta pro zobrazení úvodního rozcestníku
 */
export const RozcestnikElektrina: FC = () => {
    // methods
    const classes = useStyles();
    const {formatMessage} = useIntl();
    const {push} = useRouter();
    const {setRozcestnikData} = useRozcestnikAction();

    const {setFormStepPath} = useCommonAction();

    //redux state
    const {formKind, selfcare} = useSelector((state: StoreType) => state.common);

    // local state
    const [activeStep, setActiveStep] = useState(0);
    const [prevStep, setPrevStep] = useState(-1);
    const [pending, setPending] = useState(false);

    //local variables
    const disabledNovyOdberElektrina = isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_ELEKTRINA_SOP_NOVY_ODBER?.aktivni);

    // form
    const methods = useForm<ZadostSopWebBlokRozcestnikInput>({
        defaultValues: {
            typZadosti: disabledNovyOdberElektrina ? ZadostSopWebTypZadosti.ZMENA_STAVAJICIHO_ODBERU : ZadostSopWebTypZadosti.NOVY_ODBER,
            typNapeti: ZadostElektrinaSopTypNapeti.TRVALY_ODBER,
            hladinaNapeti: ZadostElektrinaSopHladinaNapeti.NN,
            komodita: Komodita.ELEKTRINA,
            typZmenyNaOdbernemMiste: ZadostElektrinaSopZmenaNaOdbernemMiste.ZMENA_CHARAKTERU || undefined,
        },
    });
    const {handleSubmit, control, reset} = methods;

    // form state
    const odber = useWatch({
        control,
        name: 'typZadosti',
    });

    const komodita = useWatch({
        control,
        name: 'komodita',
    });

    // stepper
    const rozcestnikSteper = getSteps(formatMessage)
        .filter(({elektrina, plyn}) => (komodita === Komodita.ELEKTRINA ? elektrina : plyn))
        .filter(({zmena, novy}) => (odber === ZadostSopWebTypZadosti.NOVY_ODBER ? novy : zmena));

    // muattions
    const [sendRezcestnik, {loading}] = useMutation<Mutation, ZadostSopWebMutationResolveFormInfoArgs>(CommonMutations.gql.resolveFormInfo, {
        onCompleted: (res) => {
            const {expectedFormStepPath} = res?.sopWeb?.resolveFormInfo;
            setFormStepPath(expectedFormStepPath);
            push({pathname: PageRoute.VSTUP}, undefined, {shallow: true});
        },
        onError: () => {
            setPending(false);
        },
    });

    if (loading || pending) {
        return <LoadingDialog open />;
    }

    const handleNext = () => {
        setPrevStep((prevState) => prevState + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setPrevStep((prevState) => prevState - 1);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = (value?: ZadostSopWebTypZadosti) => {
        setPrevStep(-1);
        setActiveStep(0);
        reset({
            komodita: Komodita.ELEKTRINA,
            typZadosti: value,
            hladinaNapeti: value === ZadostSopWebTypZadosti.NOVY_ODBER ? ZadostElektrinaSopHladinaNapeti.NN : undefined,
            typZmenyNaOdbernemMiste:
                value === ZadostSopWebTypZadosti.ZMENA_STAVAJICIHO_ODBERU ? ZadostElektrinaSopZmenaNaOdbernemMiste.ZMENA_CHARAKTERU : undefined,
        });
    };

    const onSubmit = (input: ZadostSopWebBlokRozcestnikInput) => {
        setRozcestnikData(input);
        setPending(true);
        sendRezcestnik({variables: {input}});
    };

    return (
        <PaperBlock
            titleBlock={selfcare ? undefined : <FormattedMessage id="rozcestnik" />}
            description={selfcare ? undefined : <FormattedMessage id={'formular.nove.pripojeni.elektrina.zahajeni.title.description'} />}
            centerTitle
        >
            <FormProvider {...methods}>
                {selfcare && <LoadingDialog open description="rozcestnik.selfcare" />}

                <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
                    {rozcestnikSteper.map(({title, description}, index) => {
                        const labelProps: {optional?: ReactNode} = {
                            optional: (
                                <Typography variant="body2" sx={{fontSize: '1.1rem'}}>
                                    {description}
                                </Typography>
                            ),
                        };
                        const newIndex = odber === ZadostSopWebTypZadosti.ZMENA_STAVAJICIHO_ODBERU && index === 1 ? index + 2 : index;

                        return (
                            <Step key={index} classes={{vertical: classes.vertical}}>
                                <StepLabel
                                    classes={{active: classes.active, label: classes.label}}
                                    StepIconProps={{
                                        classes: {
                                            root: classes.stepIcon,
                                        },
                                    }}
                                    {...labelProps}
                                >
                                    {title}
                                </StepLabel>
                                <StepContent
                                    classes={{root: classes.step}}
                                    TransitionProps={{in: index - prevStep === 1 || index === 0 || index === prevStep || index === prevStep - 1}}
                                >
                                    <form>
                                        <RozcestnikStepContent step={newIndex} formKind={formKind} handleReset={handleReset} />
                                    </form>
                                    {index - prevStep === 1 && (
                                        <div className={classes.actionsContainer}>
                                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                                <FormattedMessage id="button.zpet" />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={activeStep === rozcestnikSteper.length - 1 ? handleSubmit(onSubmit) : handleNext}
                                                className={classes.button}
                                                disabled={!komodita}
                                            >
                                                <FormattedMessage id="button.next" />
                                            </Button>
                                        </div>
                                    )}
                                </StepContent>
                            </Step>
                        );
                    })}
                </Stepper>
            </FormProvider>
        </PaperBlock>
    );
};
