import {Komodita} from '@eon.cz/apollo13-graphql-web';
import {CreateOutlined} from '@mui/icons-material';
import {SvgIcon} from '@mui/material';
import {SvgIconProps} from '@mui/material/SvgIcon';
import {FC} from 'react';
import {useSelector} from 'react-redux';
import {StoreType} from '../../../lib/StoreType';

type Props = SvgIconProps;

/**
 * Ikona propisky
 */
export const PenIcon: FC<Props> = (props) => {
    const komodita = useSelector((state: StoreType) => state.common.komodita);
    return (
        <>
            {komodita === Komodita.ELEKTRINA ? (
                <SvgIcon {...props}>
                    <path d=" M 6.764 20.267 L 3.39 20.267 L 3.39 16.893 L 19.283 1 C 20.227 0.099 21.713 0.099 22.657 1 C 23.587 1.933 23.587 3.442 22.657 4.374 Z M 4.919 18.738 L 6.131 18.738 L 21.576 3.293 C 21.91 2.958 21.91 2.416 21.576 2.081 C 21.237 1.757 20.703 1.757 20.364 2.081 L 4.919 17.526 Z" />
                    <path d=" M 22.499 21.796 L 0.333 21.796 L 0.333 23.324 L 22.499 23.324 C 23.087 23.324 23.455 22.687 23.16 22.178 C 23.024 21.941 22.772 21.796 22.499 21.796 Z " />
                </SvgIcon>
            ) : (
                <CreateOutlined {...props} sx={{width: props.width, height: props.height}} />
            )}
        </>
    );
};
