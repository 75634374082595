import {
    Komodita,
    ZadostElektrinaSopHladinaNapeti,
    ZadostElektrinaSopTypNapeti,
    ZadostElektrinaSopZmenaNaOdbernemMiste,
    ZadostSopWebTypZadosti,
} from '@eon.cz/apollo13-graphql-web';
import {FormControlLabel, FormHelperText, Radio, RadioGroup, Typography} from '@mui/material';
import {ChangeEvent, FC, useEffect} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {TooltipIcon} from '../../../common/components/icons/TooltipIcon';
import {Span} from '../../../common/components/styled/Span';
import {FormKind} from '../../../common/store/CommonStore';
import {isNotNullOrUndefinedOrEmpty} from '../../../common/utils/CommonUtils';

type Props = {
    readonly step: number;
    readonly formKind: FormKind | undefined;
    readonly handleReset: (value?: ZadostSopWebTypZadosti) => void;
};
/**
 * Komponenta pro zobrazení obsahu jednotlivých kroků v rozcestníku
 */
export const RozcestnikStepContent: FC<Props> = ({step, formKind, handleReset}) => {
    const {formatMessage} = useIntl();
    const {control, setError, clearErrors} = useFormContext();

    const komodita: Komodita = useWatch({
        control,
        name: 'komodita',
    });
    const hladinaNapeti: ZadostElektrinaSopHladinaNapeti = useWatch({
        control,
        name: 'hladinaNapeti',
    });
    const typZadostiForm: ZadostSopWebTypZadosti = useWatch({
        control,
        name: 'typZadosti',
    });

    const disabledNovyOdberElektrina = isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_ELEKTRINA_SOP_NOVY_ODBER?.aktivni) && komodita === Komodita.ELEKTRINA;
    const disabledZmenaOdberu = isNotNullOrUndefinedOrEmpty(formKind?.ZADOST_SOP_ZMENA_ODBERU?.aktivni) && komodita === Komodita.ELEKTRINA;

    useEffect(() => {
        if (!komodita || komodita.length === 0) {
            setError('komodita', {type: 'validate', message: formatMessage({id: 'yup.required'})}, {shouldFocus: true});
        } else {
            clearErrors('komodita');
        }
    }, [clearErrors, formatMessage, komodita, setError]);

    const handleChangeTypSmlouvy = (value: ZadostSopWebTypZadosti) => handleReset(value);

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Controller
                        name="typZadosti"
                        control={control}
                        defaultValue={ZadostSopWebTypZadosti.NOVY_ODBER}
                        render={({field: {onChange, value, onBlur, name}}) => (
                            <>
                                {Object.keys(ZadostSopWebTypZadosti)
                                    .filter((typ) => (komodita === Komodita.PLYN ? typ === ZadostSopWebTypZadosti.NOVY_ODBER : typ))
                                    .map((typZadosti) => {
                                        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
                                            e.target.value !== typZadostiForm && handleChangeTypSmlouvy(e.target.value as ZadostSopWebTypZadosti);
                                            onChange(e.target.value);
                                        };
                                        return (
                                            <RadioGroup
                                                key={typZadosti}
                                                aria-label="Typ žádosti"
                                                name="typZadosti"
                                                value={value}
                                                onChange={handleChange}
                                                onBlur={onBlur}
                                                data-testid={`form-field-${name}`}
                                            >
                                                <FormControlLabel
                                                    value={typZadosti}
                                                    control={
                                                        <Radio
                                                            disabled={
                                                                (typZadosti === ZadostSopWebTypZadosti.ZMENA_STAVAJICIHO_ODBERU && disabledZmenaOdberu) ||
                                                                (typZadosti === ZadostSopWebTypZadosti.NOVY_ODBER && disabledNovyOdberElektrina)
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <>
                                                            <FormattedMessage id={`rozcestnik.${typZadosti}.label`} />
                                                            {((typZadosti === ZadostSopWebTypZadosti.ZMENA_STAVAJICIHO_ODBERU && disabledZmenaOdberu) ||
                                                                (typZadosti === ZadostSopWebTypZadosti.NOVY_ODBER && disabledNovyOdberElektrina)) && (
                                                                <TooltipIcon
                                                                    text="tooltip.zmenaOdberu.disabled"
                                                                    placement="right-start"
                                                                    color="primary"
                                                                    fontSize="small"
                                                                />
                                                            )}
                                                        </>
                                                    }
                                                />
                                                <FormHelperText style={{marginLeft: 36, marginTop: -10, fontSize: '1.1rem'}}>
                                                    <FormattedMessage id={`rozcestnik.${typZadosti}.description`} />
                                                </FormHelperText>
                                            </RadioGroup>
                                        );
                                    })}
                            </>
                        )}
                    />
                );
            case 1:
                return (
                    <Controller
                        name="hladinaNapeti"
                        control={control}
                        defaultValue={ZadostElektrinaSopHladinaNapeti.NN}
                        render={({field: {onChange, value, onBlur, name}}) => (
                            <>
                                {Object.keys(ZadostElektrinaSopHladinaNapeti)
                                    .filter((hladinaNapeti) => hladinaNapeti !== ZadostElektrinaSopHladinaNapeti.VVN)
                                    .map((hladinaNapeti) => (
                                        <RadioGroup
                                            key={hladinaNapeti}
                                            aria-label="Hladina napětí"
                                            name="hladinaNapeti"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            data-testid={`form-field-${name}`}
                                        >
                                            <FormControlLabel
                                                value={hladinaNapeti}
                                                control={<Radio />}
                                                label={<FormattedMessage id={`rozcestnik.${hladinaNapeti}.label`} />}
                                            />
                                            <FormHelperText style={{marginLeft: 36, marginTop: -10, fontSize: '1.1rem'}}>
                                                <FormattedMessage id={`rozcestnik.${hladinaNapeti}.description`} />
                                            </FormHelperText>
                                        </RadioGroup>
                                    ))}
                            </>
                        )}
                    />
                );
            case 2:
                return (
                    <Controller
                        name="typNapeti"
                        control={control}
                        defaultValue={ZadostElektrinaSopTypNapeti.TRVALY_ODBER}
                        render={({field: {onChange, value, onBlur, name}}) => (
                            <>
                                {Object.keys(ZadostElektrinaSopTypNapeti)
                                    .filter((typNapeti) =>
                                        hladinaNapeti === ZadostElektrinaSopHladinaNapeti.VN
                                            ? typNapeti !== ZadostElektrinaSopTypNapeti.PLANOVANY_ZDROJ
                                            : typNapeti,
                                    )
                                    .map((typNapeti) => (
                                        <RadioGroup
                                            key={typNapeti}
                                            aria-label="Typ napětí"
                                            name="typNapeti"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            data-testid={`form-field-${name}`}
                                        >
                                            <FormControlLabel
                                                value={typNapeti}
                                                control={<Radio />}
                                                label={<FormattedMessage id={`rozcestnik.${typNapeti}.label`} />}
                                            />
                                            <FormHelperText style={{marginLeft: 36, marginTop: -10, fontSize: '1.1rem'}}>
                                                <FormattedMessage id={`rozcestnik.${typNapeti}.description`} />
                                            </FormHelperText>
                                        </RadioGroup>
                                    ))}
                            </>
                        )}
                    />
                );
            case 3:
                return (
                    <Controller
                        name="typZmenyNaOdbernemMiste"
                        control={control}
                        defaultValue={ZadostElektrinaSopZmenaNaOdbernemMiste.ZMENA_CHARAKTERU}
                        render={({field: {onChange, value, onBlur, name}}) => (
                            <>
                                {Object.keys(ZadostElektrinaSopZmenaNaOdbernemMiste).map((zmena) => (
                                    <RadioGroup
                                        key={zmena}
                                        aria-label="Změna na odběrném místě"
                                        name="typZmenyNaOdbernemMiste"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        data-testid={`form-field-${name}`}
                                    >
                                        <FormControlLabel
                                            value={zmena}
                                            control={<Radio />}
                                            label={
                                                <Span sx={{display: 'flex'}}>
                                                    <FormattedMessage id={`rozcestnik.${zmena}.label`} />
                                                </Span>
                                            }
                                        />
                                        <FormHelperText style={{marginLeft: 36, marginTop: -10, whiteSpace: 'pre-wrap', fontSize: '1.1rem'}}>
                                            <FormattedMessage
                                                id={`rozcestnik.${zmena}.description`}
                                                values={{
                                                    b: (chunks) => <strong>{chunks}</strong>,
                                                }}
                                            />
                                        </FormHelperText>
                                    </RadioGroup>
                                ))}
                            </>
                        )}
                    />
                );
            default:
                return <FormattedMessage id={'rozcestnik.neznamy.krok'} />;
        }
    };
    return <Typography component={'span'}>{getStepContent(step)}</Typography>;
};
